import { useEffect, useState, useRef } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldWrapper } from '../FieldWrapper';

export const PlacesAutocompleteInput = ({
  name,
  label,
  noMargin,
  defaultValue,
  setCoordinates,
  setAddresses,
  tips,
  ...rest
}: PlacesAutocompleteInputProps) => {
  const [error, setError] = useState<boolean>(false);
  const inputRef = useRef<any>(null);
  const { setValue, control } = useFormContext();

  useEffect(() => {
    const options = {
      componentRestrictions: { country: 'uk' },
      fields: ['address_components', 'formatted_address', 'geometry', 'url', 'name'],
      strictBounds: false,
    };

    if (!window.google) {
      return;
    }

    const autoComplete = new window.google.maps.places.Autocomplete(inputRef.current, options);

    const handlePlaceChanged = () => {
      setError(false);

      const place = autoComplete.getPlace();

      if (!place || !place?.geometry?.location) {
        setError(true);
        return;
      }

      const { url = '', address_components = '', formatted_address = '' } = place;

      setValue(`${name}`, formatted_address);
      setValue(`${name}Url`, url);

      if (address_components) {
        let address1 = '';
        let postcode = '';
        let locality = '';
        let country = '';

        for (const component of address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case 'street_number': {
              address1 = `${component.long_name} ${address1}`;
              break;
            }
            case 'route': {
              address1 += component.short_name;
              break;
            }
            case 'postal_code': {
              postcode = `${component.long_name}${postcode}`;
              break;
            }
            case 'postal_code_suffix': {
              postcode = `${postcode}-${component.long_name}`;
              break;
            }
            case 'postal_town':
              locality = component.long_name;
              break;
            case 'locality':
              locality = component.long_name;
              break;
            case 'country':
              country = component.long_name;
              break;
          }
        }
        setValue(`${name}Street`, address1);
        setValue(`${name}PostCode`, postcode);
        setValue(`${name}City`, locality);
        setValue(`${name}Country`, country);

        if (setAddresses) {
          setAddresses({
            street: address1,
            postCode: postcode,
            city: locality,
            country,
          });
        }
      }

      

      if (place?.geometry?.location) {
        const latitude = place?.geometry?.location?.lat();
        const longitude = place?.geometry?.location?.lng();

        setValue(`${name}Latitude`, latitude);
        setValue(`${name}Longitude`, longitude);

        if (setCoordinates) {
          setCoordinates({ lat: latitude, lng: longitude });
        }
      }
    };

    autoComplete.addListener('place_changed', handlePlaceChanged);
    return () => {
      window.google.maps.event.clearListeners(autoComplete, 'place_changed');
    };
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState }) => (
        <FieldWrapper noMargin={noMargin} tips={tips}>
          {/* <pre>{JSON.stringify({ field, fieldState }, null, 2)}</pre> */}
          <TextFieldExtended
            {...field}
            fullWidth
            {...rest}
            helperText={fieldState.error ? fieldState.error.message : ''}
            color={fieldState.error ? 'error' : 'primary'}
            inputRef={inputRef}
          />
        </FieldWrapper>
      )}
    />
  );
};

const TextFieldExtended = styled(TextField)`
  .Mui-focused {
    fieldset {
      border-left-width: 6px !important; // override inline-style
    }
  }
  .MuiFormHelperText-contained {
    margin: 0;
  }
`;

type PlacesAutocompleteInputProps = {
  setCoordinates?: (coordinates: { lat: number; lng: number }) => void;
  setAddresses?: (addresses: { street: string; postCode: string; city: string; country: string }) => void;
  name: string;
  tips?: React.ReactNode;
  noMargin?: boolean;
} & TextFieldProps;
